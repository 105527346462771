define("discourse/plugins/discourse-algolia/discourse/initializers/discourse-algolia", ["exports", "discourse/lib/plugin-api", "discourse/lib/url", "I18n", "virtual-dom", "discourse/lib/load-script", "rsvp"], function (_exports, _pluginApi, _url, _I18n, _virtualDom, _loadScript, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initializeAutocomplete(options) {
    const algoliasearch = window.algoliasearch;
    const {
      autocomplete,
      getAlgoliaResults
    } = window["@algolia/autocomplete-js"];
    const autocompleteElement = document.getElementsByClassName("algolia-search")[0].getElementsByClassName("aa-Autocomplete");
    if (autocompleteElement.length > 0) {
      return;
    }
    const searchClient = algoliasearch(options.algoliaApplicationId, options.algoliaSearchApiKey);
    const hitsPerPage = 4;
    const autocompleteSearch = autocomplete({
      container: ".algolia-search",
      panelContainer: ".algolia-autocomplete",
      debug: options.debug,
      detachedMediaQuery: "none",
      placeholder: _I18n.default.t("discourse_algolia.search_box_placeholder"),
      onSubmit: (state, event, context) => {
        _url.default.routeTo(`/search?q=${encodeURI(state.state.query)}`);
      },
      getSources() {
        return [{
          sourceId: "posts",
          getItemInputValue: _ref => {
            let {
              item
            } = _ref;
            return item.query;
          },
          getItems(_ref2) {
            let {
              query
            } = _ref2;
            return getAlgoliaResults({
              searchClient,
              queries: [{
                indexName: "discourse-posts",
                query,
                params: {
                  hitsPerPage
                }
              }]
            });
          },
          templates: {
            item(_ref3) {
              let {
                item,
                components,
                html
              } = _ref3;
              let tags = [];
              let baseTags = item.topic.tags;
              if (baseTags) {
                baseTags.forEach((baseTag, index) => {
                  tags.push(html`<a
                    class="hit-post-tag"
                    onClick="${event => {
                    _url.default.routeTo(`/tags/${baseTag}`);
                    autocompleteSearch.setIsOpen(false);
                    event.preventDefault();
                    event.stopPropagation();
                  }}"
                  >
                    ${components.Highlight({
                    hit: item,
                    attribute: ["topic", "tags", index]
                  })}
                  </a>`);
                });
              }
              return html` <div class="hit-post">
                <div class="hit-post-title-holder">
                  <span class="hit-post-topic-title">
                    ${components.Highlight({
                hit: item,
                attribute: ["topic", "title"]
              })}
                  </span>
                  <span
                    class="hit-post-topic-views"
                    title="${_I18n.default.t("discourse_algolia.topic_views")}"
                  >
                    ${item.topic.views}
                  </span>
                </div>
                <div class="hit-post-category-tags">
                  <span class="hit-post-category">
                    <span class="badge-wrapper bullet">
                      <span
                        class="badge-category-bg"
                        style="background-color: #${item.category?.color};"
                      />
                      <a
                        class="badge-category hit-post-category-name"
                        onClick="${event => {
                _url.default.routeTo(item.category.url);
                autocompleteSearch.setIsOpen(false);
                event.preventDefault();
                event.stopPropagation();
              }}"
                        >${item.category?.name}</a
                      >
                    </span>
                  </span>
                  <span class="hit-post-tags">${tags}</span>
                </div>
                <div class="hit-post-content-holder">
                  <a
                    class="hit-post-username"
                    onClick="${event => {
                _url.default.routeTo(item.user.url);
                autocompleteSearch.setIsOpen(false);
                event.preventDefault();
                event.stopPropagation();
              }}"
                    >@${item.user.username}</a
                  >:
                  <span class="hit-post-content">
                    ${components.Snippet({
                hit: item,
                attribute: "content"
              })}
                  </span>
                </div>
              </div>`;
            },
            noResults(_ref4) {
              let {
                html
              } = _ref4;
              return html`<div class="aa-empty">
                ${_I18n.default.t("discourse_algolia.no_posts")}
              </div>`;
            }
          },
          onSelect(_ref5) {
            let {
              item
            } = _ref5;
            _url.default.routeTo(item.url);
          }
        }, {
          sourceId: "users",
          getItemInputValue: _ref6 => {
            let {
              item
            } = _ref6;
            return item.query;
          },
          getItems(_ref7) {
            let {
              query
            } = _ref7;
            return getAlgoliaResults({
              searchClient,
              queries: [{
                indexName: "discourse-users",
                query,
                params: {
                  hitsPerPage
                }
              }]
            });
          },
          templates: {
            item(_ref8) {
              let {
                item,
                components,
                html
              } = _ref8;
              let likesElement = "";
              if (item.likes_received > 0) {
                likesElement = html`<span class="hit-user-like-heart">❤</span>
                  ${item.likes_received}`;
              }
              const usernameElement = components.Highlight({
                hit: item,
                attribute: item.name ? "name" : "username"
              });
              return html`<div class="hit-user-left">
                  <img
                    class="hit-user-avatar"
                    src="${options.imageBaseURL}${item.avatar_template.replace("{size}", 50)}"
                  />
                </div>
                <div class="hit-user-right">
                  <div class="hit-user-username-holder">
                    <span class="hit-user-username">
                      @${components.Highlight({
                hit: item,
                attribute: "username"
              })}
                    </span>
                    <span
                      class="hit-user-custom-ranking"
                      title="${_I18n.default.t("discourse_algolia.user_likes")}"
                    >
                      ${likesElement}
                    </span>
                  </div>
                  <div class="hit-user-name">${usernameElement}</div>
                </div>`;
            }
          },
          onSelect(_ref9) {
            let {
              item
            } = _ref9;
            _url.default.routeTo(item.url);
          }
        }, {
          sourceId: "tags",
          getItemInputValue: _ref10 => {
            let {
              item
            } = _ref10;
            return item.query;
          },
          getItems(_ref11) {
            let {
              query
            } = _ref11;
            return getAlgoliaResults({
              searchClient,
              queries: [{
                indexName: "discourse-tags",
                query,
                params: {
                  hitsPerPage
                }
              }]
            });
          },
          templates: {
            item(_ref12) {
              let {
                item,
                components,
                html
              } = _ref12;
              return html`<div class="hit-tag">
                #<span class="hit-tag-name">
                  ${components.Highlight({
                hit: item,
                attribute: "name"
              })}</span
                >
                <span
                  class="hit-tag-topic_count"
                  title="${_I18n.default.t("discourse_algolia.topic_tags")}"
                  >${item.topic_count}</span
                >
              </div> `;
            }
          },
          onSelect(_ref13) {
            let {
              item
            } = _ref13;
            _url.default.routeTo(item.url);
          }
        }];
      },
      render(_ref14, root) {
        let {
          elements,
          render,
          html
        } = _ref14;
        const {
          posts,
          users,
          tags
        } = elements;
        render(html`<div class="aa-dropdown-menu">
          <div class="left-container">
            <div class="aa-dataset-posts">${posts}</div>
          </div>
          <div class="right-container">
            <span class="aa-dataset-users">${users}</span>
            <span class="aa-dataset-tags">${tags}</span>
          </div>
          <div class="aa-footer">
            <div class="left-container">
              <a
                class="advanced-search"
                onClick="${event => {
          _url.default.routeTo("/search");
          autocompleteSearch.setIsOpen(false);
          event.preventDefault();
          event.stopPropagation();
        }}"
                >${_I18n.default.t("discourse_algolia.advanced_search")}</a
              >
            </div>
            <div class="right-container">
              <a
                target="_blank"
                class="algolia-logo"
                href="https://algolia.com/"
                title="${_I18n.default.t("discourse_algolia.powered_by")}"
              ></a>
            </div>
          </div>
        </div>`, root);
      }
    });
    return autocompleteSearch;
  }
  var _default = _exports.default = {
    name: "discourse-algolia",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.8", api => {
        api.createWidget("algolia", {
          tagName: "li.algolia-holder",
          didRenderWidget() {
            if (this.siteSettings.algolia_enabled && this.siteSettings.algolia_autocomplete_enabled) {
              _rsvp.Promise.all([(0, _loadScript.default)("/plugins/discourse-algolia/javascripts/autocomplete.js"), (0, _loadScript.default)("/plugins/discourse-algolia/javascripts/algoliasearch.js")]).then(() => {
                document.body.classList.add("algolia-enabled");
                this._search = initializeAutocomplete({
                  algoliaApplicationId: this.siteSettings.algolia_application_id,
                  algoliaSearchApiKey: this.siteSettings.algolia_search_api_key,
                  imageBaseURL: "",
                  debug: document.location.host.indexOf("localhost") > -1
                });
              });
            }
          },
          willRerenderWidget() {
            if (this._search) {
              this._search.destroy();
            }
          },
          html() {
            return [(0, _virtualDom.h)(".algolia-search"), (0, _virtualDom.h)(".algolia-autocomplete")];
          }
        });
        api.decorateWidget("header-icons:before", function (helper) {
          if (helper.widget.siteSettings.algolia_enabled && helper.widget.siteSettings.algolia_autocomplete_enabled && (!helper.widget.siteSettings.login_required || helper.widget.currentUser)) {
            return helper.attach("algolia");
          }
        });
      });
    }
  };
});